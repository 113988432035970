@import '../../assets/styles/global.scss';

.container {
  padding: 10px;
}

.price__card  {
  background: #fff;
  background-image: url('../../assets/images/price-bg.png');
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  position: relative;
  transition: all 0.3s ease;
  width: 100%;
  height: 100%;
  min-height: 300px;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: 103% 101%;
  transition: all 0.3s ease;
  border-radius: 16px;
  box-shadow: 0px 10px 10px 1px rgba(200, 200, 200, 0.25);
  cursor: pointer;

  &:hover {
    box-shadow: 0px 12px 12px 1px rgba(200, 200, 200, 0.25);
    transform: translateY(-1px);
  }
}
.price__card__pdf {
  justify-content: center;
  align-items: center;
  background: #fff;
}

.price__title {
  color: #3a3a3a;
  font-size: 18px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 10px;
  margin-left: 10px;
}

.price__footer {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
