.pagination {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  padding-block: 20px;

  &__number {
    color: #b4b4b4;
    font-weight: 500;
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }
}

.choosed {
  background-color: red;
  color: #fff;
}
