.auth {
  display: flex;
  height: 100vh;
  overflow-x: hidden;

  &__text-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 45%;
    left: 25%;
    transform: translate(-50%, -20%);
    text-align: center;
    width: 346px;
  }

  &__text {
    font-style: normal;
    font-size: 28px;
    color: #ffffff;
  }

  &__divider {
    margin: 34px auto;
    width: 113px;
    border: 1px solid #ffffff;
  }

  &__description {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    margin-top: 34px;
    line-height: 140%;
    color: #ffffff;
  }
}

.form {
  width: 70%;
  position: relative;

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    color: #141414;
    text-align: left;
  }

  &__description {
    margin-top: 45px;
    color: #b4b4b4;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }

  &__row {
    margin-top: 30px;
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    margin-top: 26px;
    font-size: 16px;
    color: #2d2c2c;
  }

  &__input-wrapper {
    position: relative;
  }

  &__input {
    border: 1px solid rgba(180, 180, 180, 0.3);
    border-radius: 10px;
    color: #141414;
    width: 383px;
    margin-top: 8px;
    padding: 20px;
    font-size: 16px;
    line-height: 22px;
    font-style: normal;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
  }

  &__visibility-toggle {
    cursor: pointer;
    position: absolute;
    top: 45%;
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    img {
      transition: 0.1s all ease;
    }
    &:hover {
      img {
        filter: brightness(0) saturate(100%) invert(16%) sepia(58%)
          saturate(5813%) hue-rotate(350deg) brightness(103%) contrast(87%);
      }
    }
  }

  &__options {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
  }

  &__buttons {
    display: flex;
    gap: 10px;
  }

  &__remember {
  }

  &__checkbox {
    background-color: #9e9e9e;
    accent-color: #e3282a;
    height: 20px;
    width: 20px;
    vertical-align: sub;
  }

  &__remember-text {
    margin-left: 10px;
  }

  &__forgot-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  &__submit {
    margin-top: 45px;
    width: 383px;
    height: 59px;
    border-radius: 10px;
    background: #e3282a;
    color: #ffffff;
    font-size: 18px;
    border: 0;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
  }
}
.auth__decrement {
  position: absolute;
  top: 10px;
  left: 10px;

  .header__greeting {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: grey;
    &:hover {
      filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
        brightness(91%) contrast(135%);
    }
  }
}
