@import '../../assets/styles/variables.scss';

.auth {

  // .auth__right
  &__decrement {
    position: absolute;
    top: 10px;
    left: 10px;

    .header__greeting {
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      color: grey;

      &:hover {
        filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg) brightness(91%) contrast(135%);
      }
    }
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
  }

  &__button-it-matched {
    font-size: 12px;
  }

  &__online-doc-checkbox-error {
    text-align: end;
    color: $default-red-color;
    right: 0;
    top: 100%;
    margin-bottom: 0.5rem;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &__address-numbers {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;

    div {
      flex: 1;
    }
  }

  &__right {
    position: relative;

    &-info {
      max-width: 70%;
    }

    &-step {
      color: $default-red-color;
      position: absolute;
      top: 40px;
      right: 60px;
    }

    &-title {
      color: #141414;
      font-size: 28px;
      font-family: Montserrat;
      font-weight: 700;
    }

    &-description {
      color: #b4b4b4;
      font-size: 18px;
      font-family: Montserrat;
      line-height: 25px;
    }
  }

  // .auth__extra-phones

  &__extra-phones {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  // .auth__phone-items

  &__phone-items {
    display: flex;
    flex-direction: column;
    margin-top: -26px;
    width: 100%;
  }

  // .auth__phone-item

  &__phone-item {
    margin-top: 20px;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    div {
      flex: 1;
    }
  }

  &__phone-header {
    display: flex;
    margin-top: 10px;
  }

  // .auth__phone-item-delete

  &__phone-item-delete {
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg);
    }
  }

  // .auth__add-phone

  &__add-phone {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: fit-content;
  }

  // .auth__add-phone-icon

  &__add-phone-icon {
    margin-top: -2px;
    margin-left: -2px;
  }

  // .auth__add-phone-text

  &__add-phone-text {
    margin-left: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  &__online-doc-checkbox-wrapper {
    display: flex;
    margin: 20px 0;
    align-items: center;
  }

  // .auth__online-doc-checkbox

  &__online-doc-checkbox {
    background-color: #9e9e9e;
    accent-color: #e3282a;
    height: 20px;
    width: 20px;
    vertical-align: sub;
    cursor: pointer;

    &:hover {
      accent-color: #e3282a;
      border-color: #e23235;
      border-width: 2px;
    }

    &-text {
      margin-left: 10px;
    }
  }

  // .auth__submit-button

  &__submit-button {}
}

.register-form {
  width: 100%;
}

.legal-entity-register-next-btn-wrp {
  margin-top: 20px;
  width: 50%;
  float: right;
}

.legal-entity-register-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  border: 1px solid #dcdee0;
  padding: 0 31px;
  min-width: 301px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;

  &__row {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    border-bottom: 1px solid rgb(220, 222, 224);
    margin-top: 20px;
    width: 100%;
    margin-bottom: 20px;
  }

  &__title {
    color: #626262;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
  }

  &__value {
    color: #2d2c2c;
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 500;
  }

  &__delete-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    visibility: hidden;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      transform: scale(1.1);
      filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg);
    }
  }

  &:hover {
    .legal-entity-register-item__delete-icon {
      visibility: visible;
    }
  }
}

.error-message {
  text-align: end;
  color: #e23235;
  right: 0;
  top: 100%;
  margin-bottom: 0.5rem;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.terms-modal {
  width: 90vw;
  height: 90vh;
  overflow-y: scroll;
}

.modal_terms {
  position: relative;
  background: #ffffff;
  padding-bottom: 71px;
}

.modal_terms_title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #2d2c2c;
  margin-top: 75px;
}

.modal_terms_content {
  width: 90%;
  margin: 70px auto 0 auto;
}

.modal_terms_cross {
  position: fixed;
  top: 50px;
  cursor: pointer;
  font-size: 23px;
  width: 24px;
  height: 24px;
  right: 70px;
  color: #8e8e8e;
}

.modal_terms_text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #343434;
}

.modal_terms_text_paragraph {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #343434;
}

.legal-entity-component__menu {
  position: absolute;
  top: 11px;
  right: 37px;
  // background: #dbdee0;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 20px;
  cursor: pointer;
}

.error-page-wrp {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-page-btn {
  position: absolute;
  top: 30px;
  width: 300px;
}

.error-page-title {
  position: absolute;
  text-align: center;
  font-size: 17px;
  top: 94px;
}