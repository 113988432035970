
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal__wrapper {
  z-index: 1001;
  width: fit-content;
  height: fit-content;
  max-height: 100vh;
  overflow-y: scroll;
  min-height: 30%;
  min-width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 17px;
}