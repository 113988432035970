.not-found {
  &__title {
    text-align: center;
    margin-top: 30px;
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    color: #20242e;
    line-height: 78px;
  }

  &__text {
    text-align: center;
    margin-top: 8px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: #20242e;
    line-height: 29px;
  }

  &__description {
    text-align: center;
    margin: 37px auto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: #20242e;
    line-height: 140%;
    width: 394px;
  }

  &__image {
    margin: 70px auto;
    display: block;
    height: 410px;
    width: 664px;
  }

  &__button {
    margin: 0 auto;
    width: 252px;
    height: 50px;
    border-radius: 10px;
    background: #e3282a;
    color: #ffffff;
    font-size: 18px;
    border: 0;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    display: block;
  }
}
