.cart-confirm {
  min-height: 588px;
  background: #fff;
  border-radius: 22px;
  margin-bottom: 10px;
  padding: 34px 46px;
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &__body {
    display: flex;
    column-gap: 1rem;
    margin-top: 15px;
    flex: 1;
    padding-top: 50px;
  }

  &__column {
    flex: 1;
    max-width: 50%;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__field-wrp {
    margin-bottom: 25px;
    width: 100%;
    position: relative;
  }

  &__field-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: #585858;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    &-error {
      color:#e23235;
    }
  }
 
}

.red-button {
  min-height: 40px;
  max-width: 250px;
  margin-top: 20px;
  margin-left: 10px;
}

.cart__total {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 354px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 15px;
  justify-content: space-between;
  background: #fff;
  padding: 42px;
  float: right;

  &_field {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #242426;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 2px;
      left: 0;
      right: 0;
      height: 1px;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
    }

    &_title {
      background: #fff;
      z-index: 2;
    }

    &_result {
      background: #fff;
      z-index: 2;
    }
  }
}

.add_address_icon_from_btn {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: red;
  color: #fff;
  padding: 4px;
}

.self-delivery-info {
  &__name {
    color: #242426;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 20px;
  }

  &__text-bold {
    color: #242426;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  &__text {
    color: #242426;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  &__indented-block {
    margin-bottom: 10px;
  }
}
.contact-choose {
  display: flex;
  padding-top: 5px;
  flex-wrap: wrap;
  gap: 15px;
  &__block {
    display: flex;
    align-items: center;
    gap: 3px;
    input {
      accent-color: rgb(227, 40, 42);
    }
    label {
      font-size: 14px;
      margin: 0;
    }
  }
}


.file-upload-icon {
  font-size: 1.5rem;
  color: #c53030; 
}
