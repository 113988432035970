$base-color: #242426;
$grey: #bcbcbc;

.filters {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: flex-end;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 1px 2px 14px 0px rgba(218, 220, 225, 0.28);
  flex-wrap: wrap;
  gap: 15px;

  &__input {
    padding: 12px;
    background-color: #eff0f3;
    font-size: 14px;
  }

  .serial_num {
    min-width: clamp(150px, 15vw, 800px);
  }
  .search {
    min-width: clamp(100px, 20vw, 800px);
  }

  &__submit {
    width: 150px;
    padding: 12px 5px;
  }
  &__clear {
    width: 40px;
    padding-block: 10px;
    background-color: white;
    border: 1.5px solid #e3282a;
    color: #e3282a;
    &:hover {
      color: white;
    }
  }
}

.devices_table {
  border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow: hidden;
  box-shadow: 1px 2px 14px 0px rgba(218, 220, 225, 0.28);
  background-color: #fff;
  &__item:nth-child(4n) {
    margin-right: 40px;
    p {
      margin-right: -40px;
    }
  }
  &__item:nth-child(4n + 1) {
    margin-left: 40px;
    p {
      margin-left: -40px;
    }
  }
  &__item:nth-child(-n + 8) {
    border-top: none;
  }

  &__title {
    color: #4d4d4d;
    font-family: Montserrat;
    font-weight: 500;
    background-color: rgba(227, 228, 230, 0.87);
    text-align: center;
    padding: 17px 0;
  }

  &__item {
    display: flex;
    justify-content: center;
    color: $base-color;
    padding: 17px 0;
    text-align: center;
    font-weight: 500;
    border-top: 1.5px solid rgba(188, 188, 188, 0.6);
    p {
      width: 120px;
    }
  }
}

.issues {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__title {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: rgba(77, 77, 77, 0.68);
    font-weight: 500;
    text-align: center;
  }

  &__item {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-radius: 15px;
    color: $base-color;
    padding: 27px 0;
    text-align: center;
    font-weight: 500;
    background-color: #fff;
    cursor: pointer;
    box-shadow: 0px 10px 10px 1px rgba(200, 200, 200, 0.25);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &:hover {
      transform: scale(1.002);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    p {
      margin: 0 auto;
      width: 140px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      padding: 0;
    }
  }
}
