/* CardLayout */
.card {
  margin: 20px;
  padding: 32px;
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: normal;
  align-content: normal;
  transition: all 0.3s ease;
  box-shadow: 3px 2px 25px 1px rgba(200, 200, 200, 0.25);
  border-radius: 15px;

  &:hover {
    box-shadow: 0px 12px 12px 1px rgba(200, 200, 200, 0.25);
    transform: translateY(-1px);
  }
}

.header {
  margin-bottom: 20px;
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  
}

.headerWithLine {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.main {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.footer {
  text-align: center;
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}