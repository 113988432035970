.tabs {
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.tabs .tab {
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: rgb(185, 180, 180);
  text-decoration: none;
}

.tabs .tab.active {
  color: black;
}
