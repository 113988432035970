@import '../../../assets/styles/variables.scss';

.form {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  background-color: #fff;
  border-radius: 1rem;
  padding: 3rem 2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e3282a;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 5px gray;
    margin-bottom: 11px;
    margin-top: 11px;
  }

  &__left,
  &__right {
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
  }

  &__field {
    margin-top: 1rem;

    &_header {
      display: flex;
      justify-content: space-between;
    }
  }

  &__image_preview {
    width: 100px;
  }

  &__label {
    margin-bottom: 0.5rem;
    color: #2d2c2c;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &__input,
  &__textarea,
  &__select {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #dcdee0;
    border-radius: 10px;
    color: #393939;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  &__input_error,
  &__textarea_error {
    border: 1px solid $default-red-color;
  }

  &__textarea {
    padding: 0.5rem;
    transition: height 0.3s;
    height: 150px;

    &:focus {
      height: 200px;
    }

    resize: none;
  }

  &__error {
    color: $default-red-color;
    font-size: 0.875rem;
    right: 0;
    top: 100%;
  }

  &__image_preview {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
  }

  &__image_wrp {
    width: 50%;
    height: 184px;
    position: relative;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: auto;
    }
    &:hover {
      .notImage__hovered {
        visibility: visible;
      }
    }
  }

  &__image_trash {
    position: absolute;
    cursor: pointer;
    top: 36%;
    right: 30%;

    &:hover {
      filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
        brightness(91%) contrast(135%);
    }
  }

  &__image {
    width: 267px;
    height: 184px;
    border-radius: 5px;
  }

  .notImage {
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 267px;
    height: 184px;
    border-radius: 5px;
    border: 2px dashed $default-red-color;
  }

  &__input_image {
    display: none;
  }

  &__label_button {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 50%;
  }

  &__input_image_plus {
  }

  &__button {
    border: 1px solid #dcdee0;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
    height: 48px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    transition: 0.2s;

    &:hover {
      background-color: #dcdee0;
    }

    &:active {
      background-color: #dcdee0;
    }
  }
}

.notImage__hovered {
  cursor: pointer;

  position: absolute;
  top: 0;
  visibility: hidden;
  color: $default-red-color;
  filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
    brightness(91%) contrast(135%);
}

.techSpecRow {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  column-gap: 10px;

  .form__field {
    width: 100%;
  }

  .techSpecInput {
    flex: 1;
    margin-right: 20px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;

    &:focus {
      outline: none;
      border-color: #007bff;
      transition: border-color 0.3s ease;
    }
  }

  &__deleteButton {
    background-color: transparent;
    border: none;
    cursor: pointer;

    svg {
      color: red;
    }
  }
}

.addButton {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  transition: background-color 0.3s ease;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background-color: #0056b3;
  }
}
