.input_field {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 10px;
  }

  &__label {
    color: #585858;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
  }

  &__error_message {
    color: #e23235;
  }

  &__input {
    padding: 16px 19px;
    border-radius: 10px;
    border: 1px solid #dcdee0;
    color: #616161;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    width: 100%;
    font-family: 'Montserrat';
    width: 100%;
    transition: 0.2s;

    &::placeholder {
      color: #bcbcbc;
    }

    &:focus,
    &:hover {
      outline: 1px solid #007aff;
      -webkit-box-shadow: 0px 0px 0px 4px rgba(0, 122, 255, 0.21);
      -moz-box-shadow: 0px 0px 0px 4px rgba(0, 122, 255, 0.21);
      box-shadow: 0px 0px 0px 4px rgba(0, 122, 255, 0.21);
    }
  }

  &__textarea {
    padding: 0.5rem;
    transition: height 0.3s;
    height: 150px;

    &:focus {
      height: 200px;
    }

    resize: none;
  }

  &__input_error {
    border: 1px solid #e23235;

    &:focus,
    &:hover {
      border: 1px solid #e23235;
      outline: 1px solid #e23235;
      -webkit-box-shadow: 0px 0px 0px 4px rgba(239, 44, 44, 0.21);
      -moz-box-shadow: 0px 0px 0px 4px rgba(239, 44, 44, 0.21);
      box-shadow: 0px 0px 0px 4px rgba(239, 44, 44, 0.21);
    }
  }
}

.input_wrapper {
  position: relative;
}

.input_icon {
  position: absolute;
  right: 5px;
  top: 7px;
  z-index: 99;
}
