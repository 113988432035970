.prices {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
}

.item_card__bar {
  background-color: #dedfe2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  border-radius: 50%;

  img {
    width: 15px;
    filter: brightness(0) saturate(100%) invert(0%) sepia(1%) saturate(7492%)
      hue-rotate(316deg) brightness(107%) contrast(100%);
  }

  &:hover {
    background-color: #e3282a;

    img {
      filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(0%)
        hue-rotate(105deg) brightness(103%) contrast(103%);
    }
  }
}

.container {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px 15px;
  min-height: 100vh;
}

.price__title {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.table__row {
  margin-bottom: 20px;

  h2 {
    font-size: 24px;
    color: #333;
    padding-bottom: 10px;
    margin-top: 30px;
    margin-bottom: 25px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 80%;
      height: 5px;
      background-color: red;
    }
  }

  table {
    th {
      background-color: #f2f2f2;
      color: #333;
      padding: 10px;
      border: 1px solid #ddd;
      text-align: center;
      width: 20%;
    }

    td {
      padding: 10px;
      border: 1px solid #ddd;
      text-align: center;
    }

    tr:nth-child(even) {
    }
  }
}
