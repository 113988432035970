.modal {
  &__text {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 24px;
  }
  &__btns {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    width: 100%;
    margin-top: 40px;
  }
}
