.order-card {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding-block: 0 1.5rem;
  border-radius: 18px;
  transition: all 0.3s ease;
  transition: max-height 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin-top: 1.75rem;
  overflow: hidden;
  &:hover {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  }

  &__header {
    transition: all 0.3s ease;
    > tr > * {
      // cells in the header
      padding-block: 1.3rem;
      color: #9b9fac;
      width: 15%;
      text-transform: full-size-kana;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: normal;
      border-bottom: 1px solid #e9edf2;
    }
  }

  &__content {
    > tr > * {
      padding-top: 1.5rem;
      border: none;
    }
  }

  &--expanded {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    .order-card__header {
      background-color: #e3282a;
      > tr > * {
        // cells in the header when card expanded
        color: #fff;
      }
    }
  }

  &-loader {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 32px;
    object-fit: contain;
    z-index: 99;
    top: 27px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 10px;
  }

  &.expanded {
    max-height: 1000px;
  }

  .order-details {
    margin-top: 20px;
    padding: 20px;
    border-top: 1px solid #ddd;
    background: #ffffff;

    p {
      margin: 0;
      padding: 10px 0;
      font-size: 16px;
      line-height: 24px;
      color: #333;

      strong {
        font-weight: 600;
        color: #000;
      }
    }
  }
  &__actions {
  }
  &__status {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
  }
}
.order-card-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 10px;
  align-items: center;
  background-color: red;
}

.orders-rectangle-column-large {
  flex: 3;
}

.orders-rectangle-column-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #9e9e9e;
}

.order-card-order {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #3a3a3a;
}

.orders-rectangle-item {
  display: flex;
  align-items: flex-start;
}

.orders-rectangle-photo-wrapper {
  display: flex;
}

.orders-rectangle-photo {
  max-width: 60px;
}

.order-card-content-text {
  flex: 1;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-transform: capitalize;
  color: #2d2c2c;
}

.order-card-content-price {
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  text-transform: capitalize;
  color: #2d2c2c;
}

.order-card-status {
}

.photo {
  display: flex;
}

.open {
  color: #0561fc;
}

.close {
  color: #28c760;
}

@media (max-width: 700px) {
  .orders-rectangle__info {
    padding: 10px;
    flex-direction: column;
  }

  .orders-rectangle-column {
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: space-between;
  }
}

.canceled {
  border: 1.5px solid #f44336;
}