.filter {
  width: 100%;

}

.filter__category {
  margin-top: 10px;
}

.filter__options {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-top: 10px;
}

.filter__products_inCart {
  margin-top: 10px;
  min-height: 150px;
  overflow-y: scroll;
}

.filter__price {
  white-space: nowrap;
  color: #141414;
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18px;
  min-width: 20%;
  padding: 5px;
}

.filter__quantity_label {
  white-space: nowrap;
}

.filter__input {
  text-align: center;
  font-weight: 600;
  width: 92px;
  height: 41px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #333435;
}