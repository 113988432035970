.contacts {
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  margin: 20px 0;
  padding-block: 30px 60px;
  padding-inline: 40px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
  &__title {
    color: #242426;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
  &__body {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px 0;
    justify-content: space-between;
  }
}

.info {
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  gap: 34px;

  &__managers {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  &__title {
    text-align: center;
    background-color: #eff0f3;
    padding: 10px 20px;
    font-weight: 600;
    border-radius: 15px;
    width: 100%;
  }
  &__chunk {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__text {
    font-family: 'Montserrat';

    &--bold {
      font-weight: 600;
    }
    &--medium {
      font-weight: 500;
    }
    &--time {
      width: 190px;
    }
  }
  &__icons {
    margin-top: 5px;
    display: flex;
    gap: 20px;
  }
}

.manager_block__container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  //flex-direction: column;
}

.contacts_avatar {
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-left: 10px;
}
