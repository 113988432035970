.datepicker {
  width: 100%;
  min-height: 44px;
  padding: 0.5rem;
  border: 1px solid #dcdee0;
  border-radius: 10px;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.2s;
  &__icon {
    position: absolute;
    top: 66%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &:focus,
  &:hover {
    outline: 1px solid #007aff;
    -webkit-box-shadow: 0px 0px 0px 4px rgba(0, 122, 255, 0.21);
    -moz-box-shadow: 0px 0px 0px 4px rgba(0, 122, 255, 0.21);
    box-shadow: 0px 0px 0px 4px rgba(0, 122, 255, 0.21);
  }
}
.weekday {
  font-family: 'Montserrat';
  color: #6e6e6e !important;
  font-weight: 500;
  text-transform: uppercase;
}
.day {
  text-align: center;
  font-family: 'Montserrat';
  letter-spacing: 1.5px;
  background-color: #fff;
  font-weight: 500;
  color: #b4b4b4 !important;
}
.current-day {
  background-color: #e3282a !important;
  font-family: 'Montserrat';
}
.calendar {
  padding: 5px 15px 15px;
  background-color: #fff;
  border: none !important;
  border-radius: 10px !important;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.08),
    0px 15px 35px -5px rgba(17, 24, 38, 0.13);
}
