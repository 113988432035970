.item_card__image {
  background-color: #dedfe2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  border-radius: 50%;

  img {
    width: 15px;
    filter: brightness(0) saturate(100%) invert(0%) sepia(1%) saturate(7492%) hue-rotate(316deg) brightness(107%) contrast(100%);
  }

  &:hover {
    background-color: #e3282a;

    img {
      filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(0%) hue-rotate(105deg) brightness(103%) contrast(103%);
    }
  }

}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}


.modal__wrapper {
  z-index: 1001;
  width: 50%;
  height: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 17px;
}

.modal__confirm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.modal__text {
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  font-weight: 600;
}