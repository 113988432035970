.tabs {
    width: 100%;
  }
  .tabs-list {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    list-style: none;
    margin-top: 30px;
    background-color: #eff0f3;
    padding-top: 5px;
  }
  
  .tab-item {
    display: flex;
    position: relative;
    gap: 5px;
    align-items: center;
    padding-block: 10px;
    cursor: pointer;
    padding-inline: 10px;
    color: #b4b6bc;
    font-weight: 600;
    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 50%;
      width: 0%;
      right: 0;
      height: 3px;
      background-color: #e3282a;
      display: inline-block;
      transform: translateX(-50%);
      transition: all 0.2s ease-out;
      border-radius: 10px 10px 0 0;
    }
    &__icon {
      width: 25px;
    }
  }
  
  .tab-item--active {
    color: #242426;
    &::after {
      width: 100%;
    }
    .tab-item__icon {
      filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
        brightness(91%) contrast(135%);
    }
  }
  
  .detail__list {
    display: flex;
    margin-top: 1rem;
    transition: 0.3s;
  }
  .tabs-content {
    margin-top: 10px;
  }