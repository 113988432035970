@import '../../../assets/styles/variables.scss';

.avatar__image_preview {
  &__image_preview {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    min-height: 180px;
  }
}
.avatar {
  &__info {
    max-width: 400px;
    text-align: center;
  }
}

.personalImage {
  text-align: center;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.label {
  input[type='file'] {
    display: none;
  }
}

.personalFigure {
  position: relative;
  width: 120px;
  height: 120px;
}

.personalAvatar {
  cursor: pointer;
  width: 120px;
  height: 120px;
  box-sizing: border-box;
  border-radius: 100%;
  border: 2px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  transition: all ease-in-out 0.3s;
  object-fit: cover;

  &:hover {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }
}

.personalFigcaption {
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 0px;
  width: inherit;
  height: inherit;
  border-radius: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-in-out 0.3s;

  filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
    brightness(91%) contrast(135%);

  &:hover {
    opacity: 1;
    .personalAvatar {
      border-color: $default-red-color;
    }
  }

  .avatar__image_edit {
    margin-top: 32.5px;
    width: 50px;
    height: 50px;
  }
}

.avatar__image_trash {
  position: absolute;
  cursor: pointer;
  left: 85px;
  bottom: 40%;

  background: transparent;
  padding: 5px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(0%)
    hue-rotate(105deg) brightness(103%) contrast(103%);

  &:hover {
    img {
      filter: brightness(0) saturate(100%) invert(0%) sepia(1%) saturate(7492%)
        hue-rotate(316deg) brightness(107%) contrast(100%);
    }
  }
}

.avatar__image_preview {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}
