@import '../../../assets/styles/variables.scss';

.header {

  &__back {
    font-weight: 500;
    color: grey;
    &:hover {
      filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg) brightness(91%) contrast(135%);
    }
  }

  &__back-image {
      object-fit: contain;
  }
}
