.main-wrp {
  margin-top: 10px;
  border-radius: 1rem;
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
  }

  &__title {
    color: #3a3a3a;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
}
