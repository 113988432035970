.tabs {
  width: 100%;
}

.tabsList {
  display: flex;
  gap: 10px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tabItem {
  padding-block: 10px;
  cursor: pointer;
}

.tabItem.active {
  font-weight: bold;
}

.underline {
  position: relative;
}

.underline::after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 50%;
  width: 0%;
  right: 0;
  height: 2px;
  background-color: #e3282a;
  display: inline-block;
  transform: translateX(-50%);
  transition: all 0.4s ease-out;
}

.underline.active::after {
  width: 100%;
}

.detail__list {
  display: flex;
  margin-top: 1rem;
  transition: 0.3s;
}

.booking {
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  color: #2d2c2c81;
  border-top: 1px solid transparent;
  border-inline: 1px solid transparent;
  border-bottom: 1px solid #2d2c2c36;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  transition: all 0.2s ease-in;
}

.booking.active {
  color: #2d2c2c;
  border-top: 1px solid #2d2c2c36;
  border-inline: 1px solid #2d2c2c36;
  border-bottom: 1px solid transparent;
  font-weight: 600;
  border-radius: 10px 10px 0 0;
}

.tabsContent {
  margin-top: 20px;
}
