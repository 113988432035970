@import './assets/styles/global.scss';

html,
body,
#root,
.App {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat';
}

.sidebar {
  height: 100%;
  background-color: #f8f9fa;
  overflow-y: auto;
  padding: 0;
}

.content {
  height: 100vh;
  overflow-y: auto;
  background-color: #eff0f3;
}

.module-title {
  color: #3a3a3a;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-left: 4%;
  margin-top: 44px;
}

a {
  text-decoration: none;
}
ul {
  list-style: none;
}
p {
  margin: 0;
}

.main-wrp {
  margin-top: 10px;
  border-radius: 1rem;
  /* padding: 20px 60px; */
  min-height: 100vh;
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
  }

  &__title {
    color: #3a3a3a;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
}

.react-datepicker__header {
  background-color: #fff !important;
  font-family: 'Montserrat';
  border-bottom: none !important;
  padding-bottom: 0 !important;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__navigation {
  top: 13px !important;
  box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16),
    0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  width: 25px !important;
  height: 20px !important;
}
.react-datepicker__current-month {
  font-family: 'Montserrat' !important;
  font-size: 14px !important;
  color: #242426 !important;
  font-weight: 600 !important;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.react-datepicker__navigation--next {
  right: 26px !important;
}
.react-datepicker__navigation--previous {
  left: 26px !important;
}

.react-datepicker__navigation-icon--next::before {
  border-color: #b4b4b4 !important;
  border-width: 1.5px 1.5px 0 0 !important;
  top: 13px !important;
  left: -2px !important;
  height: 5px !important;
  width: 5px !important;
}
.react-datepicker__navigation-icon--previous::before {
  border-color: #b4b4b4 !important;
  border-width: 1.5px 1.5px 0 0 !important;
  top: 12.5px !important;
  left: -4px !important;
  height: 5px !important;
  width: 5px !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: #fff !important;
}
.react-datepicker__day--in-selecting-range {
  background-color: #e3282b80 !important;
  color: white !important;
}

.react-datepicker__day--in-range {
  background-color: #e3282b !important;
  color: white !important;
}
button.react-datepicker__close-icon {
  display: none !important;
}
