@import '../../../assets/styles/variables.scss';
.form {
  &__field {
    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      line-height: normal;
    }
  }

  &__label {
    color: #585858;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    line-height: normal;
    margin-bottom: 0;
  }
  &__input,
  &__textarea {
    padding: 16px 19px;
    border-radius: 10px;
    border: 1px solid #dcdee0;
    color: #616161;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    width: 100%;
    font-family: 'Montserrat';
    width: 100%;
    transition: 0.2s;
    &::placeholder {
      color: #bcbcbc;
    }
  }

  &__input_error,
  &__textarea_error {
    border: 1px solid $default-red-color;
  }

  &__textarea {
    padding: 0.5rem;
    transition: height 0.3s;
    height: 150px;

    &:focus {
      height: 200px;
    }

    resize: none;
  }

  &__error {
    color: $default-red-color;
    font-size: 0.875rem;
    right: 0;
    top: 100%;
    &_length {
      color: #f1776e;
    }
  }

  &__length {
    color: #585858;
  }
}
