.orders {
  background: #fff;
  margin-top: 10px;
  border-radius: 1rem;
  padding: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__search {
    padding: 13px 12px;
    border-radius: 11px;
    border: 1px solid #0000001f;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  p {
    margin-top: 30px;
  }
}
