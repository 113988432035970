.info-table {
    &__header {
      > tr > * {
        align-items: flex-start;
        padding: 1.3rem;
        color: #9b9fac;
        text-transform: full-size-kana;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        border-bottom: 1px solid #e9edf2;
      }
    }
  
    &__content {
      > tr > * {
        vertical-align: text-top;
        font-family: Montserrat;
        font-weight: 500;
        color: #242426;
      }
    }
  }