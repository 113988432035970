@import '../../../assets/styles/variables.scss';

.form {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  background-color: #fff;
  border-radius: 1rem;
  padding: 3rem 2rem;

  div {
    flex: 1;
  }

  &__field {
    margin-top: 2rem;
    margin-bottom: 2rem;

    &_header {
      display: flex;
      justify-content: space-between;
    }
  }

  &__image_preview {
    width: 100px;
  }

  &__checkboxes {
    display: flex;
    align-items: center;

    .form__label {
      margin-bottom: 0;
      margin-left: 5px;
    }
  }

  &__checkbox {
    width: 30px;
    accent-color: '#E3282A';
    vertical-align: 'sub';
  }

  &__label {
    margin-bottom: 0.5rem;
    color: #2d2c2c;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &__input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #dcdee0;
    border-radius: 10px;
    color: #393939;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  &__input_error {
    border: 1px solid $default-red-color;
  }

  &__error {
    color: $default-red-color;
    font-size: 12px;
    right: 0;
    top: 100%;
  }

  &__image_preview {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  }

  &__image_wrp {
    width: 50%;
    height: 100px;
    position: relative;
  }

  &__image_trash {
    position: absolute;
    cursor: pointer;

    &:hover {
      filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
        brightness(91%) contrast(135%);
    }
  }

  &__image {
    padding: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .notImage {
    padding: 10px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 2px dashed $default-red-color;
  }

  &__input_image {
    display: none;
  }

  &__label_button {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
  }

  &__input_image_plus {
  }

  &__button {
    border: 1px solid #dcdee0;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
    height: 48px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    transition: 0.2s;

    &:hover {
      background-color: #dcdee0;
    }

    &:active {
      background-color: #dcdee0;
    }
  }
}
