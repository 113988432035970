.pfd-container {
  width: 100%;
  height: 900px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prices-container {
  background: #fff;
  min-height: 100vh;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.prices__upper-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 10px;
}

.prices__upper-block-group {
  align-self: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
