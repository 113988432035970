.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.companyCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 15px;
  position: relative;
  text-decoration: none;
  transition: all 0.3s ease;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 10px 10px 1px rgba(200, 200, 200, 0.25);
  cursor: pointer;

  &:hover {
    box-shadow: 0px 12px 12px 1px rgba(200, 200, 200, 0.25);
    transform: translateY(-1px);
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-bottom: 0.5px solid rgb(181, 173, 173);
  }

  &__legal_entities {
    align-items: center;
    display: flex;
    column-gap: 0.5rem;
    margin-top: 10px;
  }

  // .companyCard__circle-image

  &__circle_image {
    width: 60px;
    object-fit: cover;
    height: 60px;
    border-radius: 50%;
  }

  // .companyCard__legal-entity

  &__legal_entity {
  }

  // .companyCard__data

  &__data {
  }

  // .companyCard__manager

  &__manager {
    color: #3a3a3a;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32p
  }

  // .companyCard__data_item

  &__data_item {
    display: flex;
    font-style: normal;
    margin-top: 10px;
    column-gap: 0.5rem;
    width: fit-content;
    cursor: pointer;

    &:hover {
      filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
        brightness(91%) contrast(135%);
    }
  }

  // .companyCard__data_icon

  &__data_icon {
  }

  // .companyCard__data_value

  &__data_value {
    a {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #191d23;
      mix-blend-mode: normal;
      opacity: 0.9;
    }
  }

  // .companyCard__body

  &__body {
  }
}

.companyCard__link {
  text-decoration: none;
}


.companyCard__footer__button {
  margin-top: 10px;
}