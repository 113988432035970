@import '../../../assets/styles/variables.scss';

.field {
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 10px;

  &_header {
    display: flex;
    justify-content: space-between;
  }
}

.label {
  margin-bottom: 0.5rem;
  font-family: 'Montserrat';
  color: #2d2c2c;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.input {
  font-family: 'Montserrat';
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #dcdee0;
  border-radius: 10px;
  font-size: 18px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.2s;

  &:focus,
  &:hover {
    outline: 1px solid #007aff;
    -webkit-box-shadow: 0px 0px 0px 4px rgba(0, 122, 255, 0.21);
    -moz-box-shadow: 0px 0px 0px 4px rgba(0, 122, 255, 0.21);
    box-shadow: 0px 0px 0px 4px rgba(0, 122, 255, 0.21);
  }
}

.input.input_error {
  border: 1px solid $default-red-color;
  &:focus,
  &:hover {
    border: 1px solid $default-red-color;
    outline: 1px solid $default-red-color;
    -webkit-box-shadow: 0px 0px 0px 4px rgba(239, 44, 44, 0.21);
    -moz-box-shadow: 0px 0px 0px 4px rgba(239, 44, 44, 0.21);
    box-shadow: 0px 0px 0px 4px rgba(239, 44, 44, 0.21);
  }
}

.error {
  color: $default-red-color;
  text-align: end;
  right: 0;
  top: 0%;
  margin-bottom: 0.5rem;
  font-family: 'Montserrat';
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.icon_button {
  cursor: pointer;
  border: none;
  background: none;
  width: 25px;
  right: 5px;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 99;

  &:hover {
    filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
      brightness(91%) contrast(135%);
  }
}

.icon {
  background: none;
  padding: 0;

  :hover {
    background-color: none;
  }
}

.form-control {
  position: relative;
}

.input_wrapper {
  position: relative;
}

.field-error {
  position: absolute;
  top: 0;
  right: 10%;
  color: red;
  background-color: #fff;
  padding: 2px 5px;
  font-size: 0.8rem;
  border-radius: 4px;
}
