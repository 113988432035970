.cart {
  display: flex;
  flex-direction: column;

  &__steps {
    display: flex;
    align-items: center;
    column-gap: 20px;
    width: 100%;
  }
}

.cart-confirm {
  min-height: 588px;
  background: #fff;
  border-radius: 22px;
  margin-bottom: 10px;
  padding: 34px 46px;
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  &__body {
    display: flex;
    column-gap: 1rem;
    margin-top: 15px;
    flex: 1;
    padding-top: 50px;
    justify-content: space-between;
  }

  &__column {
    flex: 1;
    width: 50%;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &-options {
    min-height: 700px;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &-left {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-right: 1px solid lightgrey;
    }

    &-right {
      flex: 1;
    }
  }

  &__field-wrp {
    margin-bottom: 25px;
    width: 100%;
  }

  &__field-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    color: #585858;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
  }
}

.cart-item-comment-wrapper {
  width: 100%;
  height: 100%;
}

.red-button {
  min-height: 40px;
  max-width: 250px;
  margin-top: 20px;
  margin-left: 10px;
}

.cart__total {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 354px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 15px;
  justify-content: space-between;
  background: #fff;
  padding: 42px;
  float: right;

  &_field {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #242426;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 2px;
      left: 0;
      right: 0;
      height: 1px;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
    }

    &_title {
      background: #fff;
      z-index: 2;
    }

    &_result {
      background: #fff;
      z-index: 2;
    }
  }
}

$primaryColor: #333;
$secondaryColor: #666;
$gray: #999;

.selfDeliveryData {
  display: flex;
  gap: 80px;
  margin-top: 1rem;

  .box {
    max-width: 100%;
    gap: 1rem;
  }

  .contactInfo {
    margin-bottom: 20px;
  }

  .contactAddress {
    margin-bottom: 20px;
    color: var(--, #242426);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  .boldText {
    color: var(--, #242426);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 20px;
  }

  .text {
    color: $primaryColor;
    white-space: pre-line;
  }

  .contactSpan {
    color: var(--, #242426);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    display: block;
  }
}

.add_address_icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: red;
  color: #fff;
  padding: 4px;
}

.input_field {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__label {
    color: #585858;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
  }

  &__error_message {
    color: #e23235;
  }

  &__input {
    padding: 16px 19px;
    border-radius: 10px;
    border: 1px solid #dcdee0;
    color: #616161;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    width: 100%;
    font-family: 'Montserrat';
    width: 100%;
    transition: 0.2s;

    &:focus,
    &:hover {
      outline: 1px solid #007aff;
      -webkit-box-shadow: 0px 0px 0px 4px rgba(0, 122, 255, 0.21);
      -moz-box-shadow: 0px 0px 0px 4px rgba(0, 122, 255, 0.21);
      box-shadow: 0px 0px 0px 4px rgba(0, 122, 255, 0.21);
    }
  }

  &__textarea {
    padding: 0.5rem;
    transition: height 0.3s;
    height: 150px;

    &:focus {
      height: 200px;
    }

    resize: none;
  }

  &__input_error {
    border: 1px solid #e23235;

    &:focus,
    &:hover {
      border: 1px solid #e23235;
      outline: 1px solid #e23235;
      -webkit-box-shadow: 0px 0px 0px 4px rgba(239, 44, 44, 0.21);
      -moz-box-shadow: 0px 0px 0px 4px rgba(239, 44, 44, 0.21);
      box-shadow: 0px 0px 0px 4px rgba(239, 44, 44, 0.21);
    }
  }
}

.self-delivery-info {
  &__name {
    color: #242426;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 20px;
  }

  &__text-bold {
    color: #242426;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  &__text {
    color: #242426;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  &__indented-block {
    margin-bottom: 10px;
  }
}
