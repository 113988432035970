.cartConfirmBlock {
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.12px;
  text-align: center;
  padding: 11px;
  max-width: 190px;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(225, 227, 232, 1);
  color: rgba(108, 108, 108, 0.65);
}

.active {
  background-color: rgba(227, 40, 42, 1);
  color: #fff;
}
