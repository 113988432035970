.select_wrapper {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 0.5rem;
  font-family: 'Montserrat';
  color: #2d2c2c;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
}
