.detail {
  border-radius: 20px;
  background: #fff;
  min-height: 100vh;

  &__upper {
    display: flex;
    border-bottom: 2px solid #3434341a;
  }

  // .detail__img-wrp

  &__img-wrp {
    width: 44%;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #3434341a;
  }

  // .detail__img
  &__img {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    padding: 2rem;
    max-width: 350px;
    max-height: 350px;
  }

  // .detail__info

  &__info {
    flex: 1;
    padding: 30px 40px;
  }

  // .detail__name

  &__name {
    color: #2d2c2c;
    font-size: 22px;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 130%;
    height: 62px;
  }

  // .detail__description

  &__description {
    min-height: 87px;
    color: #343434;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 150%;
  }

  // .detail__settings

  &__settings {
    display: flex;
    margin-top: 100px;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;
    max-width: 500px;
  }

  // .detail__input
  &__input {
    text-align: center;
    font-weight: 600;
    width: 92px;
    height: 41px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #333435;
    &__btn {
      
    }
  }

  // .detail__price

  &__price {
    color: #141414;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 700;
    line-height: 18px;
  }

  // .detail__btn

  &__btn {
    width: 37%;
    padding: 30px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  &__group-btn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    max-width: 500px;
  }

  &__lower {
    padding: 0 80px;
    min-height: 100px;
    // margin-top: 200px;
  }

  &__tabs {
    display: flex;
    justify-content: space-between;
    width: 65%;
  }

  &__tab {
    color: rgba(45, 44, 44, 0.5);
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 130%;
    text-transform: capitalize;
    cursor: pointer;
  }

  &__tab--active {
    color: #2d2c2c;
  }

  &__product-options {
    margin-top: 27px;
  }

  // .detail__description-lower

  &__description-lower {
    color: #343434;
    font-size: 14px;
    font-family: Montserrat;
    font-weight: 500;
    padding-bottom: 60px;
    line-height: 150%;
  }
}

@media (max-width: 768px) {
  .detail {
    &__upper {
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
    }

    &__info {
      padding: 10;
    }

    &__description {
      margin-top: 20px;
    }

    &__settings {
      margin-top: 140px;
      width: 100%;
    }

    &__btn {
      width: 100%;
    }

    &__lower {
      padding: 10px;
    }

    &__tabs {
      flex-direction: column;
      width: 100%;
    }
  }
}

.product {
  margin-top: 20px;
}

.group-price-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.detail__list {
  display: flex;
  margin-top: 1rem;
  transition: 0.3s;
}

.detail__list-tab {
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  color: #2d2c2c81;
  border-bottom: 1px solid #2d2c2c36;
  box-sizing: border-box;
}


.detail__list-tab--active {
  color: #2d2c2c;
  border-top: 1px solid #2d2c2c36;
  border-right: 1px solid #2d2c2c36;
  border-left: 1px solid #2d2c2c36;
  border-bottom: 1px solid transparent;
  font-weight: 600;
  border-radius: 10px 10px 0 0;
}

.detail__lower-description {
  margin-top: 20px;
}