@import '../../../assets/styles/variables.scss';

.button {
  width: 100%;
  max-height: 48px;
  height: 100%;
  padding: 14px 20px;
  border: none;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default {
  background: $button-color;
  color: #ffffff;

  &:hover {
    background: $button-color-hover;
  }

  &:active {
    background: $button-color-active;
  }

  &:disabled {
    background-color: $button-color-disabled;
  }
}

.light {
  background-color: #fff;
  color: #333;
  border: 1px solid #333;

  &:hover {
    background-color: #e6e6e6;
    color: #333;
  }

  &:active {
    background-color: #ccc;
    color: #333;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    background-color: #ccc;
    color: #888;
    border-color: #888;
  }
}

.danger {
  background-color: #fff;
  color: $button-color;
  border: 2px solid $button-color;

  &:hover {
    background-color: $button-color;
    color: #fff;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.3);
  }
}

.spinner {
  width: 24px;
  height: 24px;
  filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(0%)
    hue-rotate(105deg) brightness(103%) contrast(103%);
}
