.legal-entity {
  min-width: 100%;
  border-radius: 20px;
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 1);
  margin-bottom: 16px;
  height: 100%;
  background-color: #fff;

  &__header {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1.2px solid rgba(220, 222, 224, 1)
    ;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }

  &__body {
    padding: 8px 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__footer {
    padding: 8px 20px;
    border-top: 1.2px solid rgba(220, 222, 224, 1);
    display: flex;
    column-gap: 10px;
  }
}


.label {
  font-weight: bold;
}
