.bar {
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  padding-bottom: 40px;

  &__header {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__image {
    height: 53px;
  }

  .logout-wrapper {
    margin-top: 40px;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  box-sizing: border-box;

  &__option-icon {
    width: fit-content;
  }

  &__option-wrapper {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-top: 2rem;
    padding-left: 25%;
    cursor: pointer;

    &-mobile {
      width: auto;
      padding: 0;
      margin: 0;
    }

    &:hover {
      .menu__option-text {
        color: #000;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }

  &__option-text {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #c4c4c4;
    margin-left: 12px;
    cursor: pointer;
    white-space: nowrap;
  }

  &__slider {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 4px;
    height: 100%;
    background-color: #e3282a;
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.3s ease;
  }
}

.active {
  .menu__slider {
    transform: scaleX(1);
    z-index: 1;
  }

  .menu__option-text {
    color: #000;
  }

  .menu__option-icon {
    filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
      brightness(91%) contrast(135%);
  }
}
