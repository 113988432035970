.item_card__image {
  background-color: #dedfe2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;
  border-radius: 50%;

  img {
    width: 15px;
    filter: brightness(0) saturate(100%) invert(0%) sepia(1%) saturate(7492%)
      hue-rotate(316deg) brightness(107%) contrast(100%);
  }

  &:hover {
    background-color: #e3282a;

    img {
      filter: brightness(0) saturate(100%) invert(95%) sepia(100%) saturate(0%)
        hue-rotate(105deg) brightness(103%) contrast(103%);
    }
  }
}

.footer {
  padding: 8px 20px;
  border-top: 1.2px solid rgba(220, 222, 224, 1);
  display: flex;
  justify-content: center;
  column-gap: 10px;
}
