.invites-wrp {
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  background: #fff;
  border-radius: 10px;
}

.invites {
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  &__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 2rem;
  }
}

.invites .tab {
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: rgb(185, 180, 180);
  text-decoration: none;
}

.invites .tab.active {
  color: black;
}

.form-group {
  position: relative;
}

.clipboard-btn {
  top: 54%;
  right: 6px;
  position: absolute;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    .copy-icon {
      filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
        brightness(91%) contrast(135%);
    }
  }
}

.copy-icon {
  cursor: pointer;

  &:hover {
    filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
      brightness(91%) contrast(135%);
  }
}

.cookie-card {
  padding: 1rem;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-top: 1rem;
}

.form-control {
  margin-bottom: 20px;
}

.title {
  font-weight: 600;
  color: rgb(31 41 55);
}

.description {
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgb(75 85 99);
}

.description a {
  --tw-text-opacity: 1;
  color: rgb(59 130 246);
}

.description a:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.actions {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
  flex-shrink: 0;
}

.pref:hover {
  color: rgb(156 163 175);
}

.pref:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.accept {
  font-size: 0.75rem;
  line-height: 1rem;
  background-color: rgb(17 24 39);
  font-weight: 500;
  border-radius: 0.5rem;
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border: none;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.accept:hover {
  background-color: rgb(55 65 81);
}

.accept:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.list_group {
  list-style-type: none;
  padding: 0;
}
