.item {
  flex-shrink: 0;
  width: 100%;
  border-radius: 17px;
  background: #fff;
  padding: 14px 22px;
  margin-bottom: 1rem;
  align-items: center;
  font-family: Montserrat;

  &__image {
    width: 66px;
    height: 76px;
    object-fit: contain;
    object-position: center;
  }
}

.table {
  .th {
    font-family: Montserrat;
    color: #bcbcbc;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.13px;
    padding: 0 10px;
  }

  .td {
    font-family: Montserrat;
    color: #242426;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    text-transform: capitalize;
    padding: 14px 10px;
    width: 25%;
  }
}
