.dashboard_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  transition: 0.3s;
  min-height: 100%;
  min-height: 404px;
  // &:hover {
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  // }

  &__title {
    color: #3a3a3a;
    font-family: 'Montserrat';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  &__content {
    padding-top: 16px;
    padding-bottom: 24px;
    position: relative;
    height: 100%;
  }

  &__link {
    float: right;
  }
}
.dashboard_catalog_card {
  width: 100%;
  height: 100%;
  max-width: 300px;
  min-height: 200px;
  border-radius: 20px;
  background: #f6f7fa;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &__image {
    width: 108px;
    height: 96px;
  }

  &__name {
    color: #141414;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
  }
}
.dashboard_manager__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_header {
    display: flex;
    align-items: center;
    column-gap: 25px;
    position: relative;

    ::after {
      content: '';
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: calc(100% + 30px);
      height: 2px;
      background: #e0e0e0;
      position: absolute;
    }
  }

  &_image {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 50%;
    object-fit: cover;
  }

  &_name {
    color: #242426;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
}
.dashboard_card__prices {
  background-image: url('../../assets/images/group-bg.png');
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 50%;

  &_item {
    color: #242426;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 27px;
    position: relative;
    padding-left: 10px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 4px;
      background-color: #e3282a;
      border-radius: 5px;
    }
  }
}
.dashboard_card__order_item {
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-style: normal;
  line-height: 21px;
  margin-bottom: 20px;

  &_date {
    color: #121212;
    font-size: 12px;
    font-weight: 600;
  }

  &_info {
    font-size: 12px;
    font-weight: 400;
  }

  &_id {
    color: #2b47fc;
  }

  &_company {
    color: #e3282a;
    margin-left: 1rem;
  }
}
.dashboard_card__cart_table {
  &_head {
    color: #9e9e9e;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  // &_row {}
  &_title {
    text-align: left;
  }
  // &_body {}
  &_name {
    color: #2d2c2c;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }

  &_quantity {
    color: #3a3a3a;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &_image {
    width: 54px;
    height: 51px;
    flex-shrink: 0;
    border-radius: 50%;
    margin-right: 1rem;
    object-fit: cover;
  }
}
.dashboard_user__item {
  display: flex;
  margin-bottom: 15px;

  &_image {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    flex-shrink: 0;
    object-fit: cover;
  }

  &_name {
    color: #242426;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding-left: 15px;
  }
}


.container {  display: grid;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  grid-template-rows: 1.3fr 1.3fr 1.3fr 1fr 0.4fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "catalog catalog catalog catalog catalog catalog catalog manager manager manager"
    "prices prices prices cart cart cart cart companies companies companies"
    "orders orders orders . . . . . . ."
    ". . . . . . . . . ."
    ". . . . . . . . . .";
}

.catalog { grid-area: catalog; }

.manager { grid-area: manager; }

.prices { grid-area: prices; }

.cart { grid-area: cart; }

.companies { grid-area: companies; }

.orders { grid-area: orders; }
