.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.image {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
