.contactCard__data {
  &_item {
    display: flex;
    font-style: normal;
    margin-top: 2rem;
    column-gap: 0.5rem;
    width: fit-content;
    cursor: pointer;
    align-items: center;

    &:hover {
      filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
        brightness(91%) contrast(135%);
    }
  }
  &_icon {
    width: 18px;
  }
  &_value {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    color: #191d23;
    mix-blend-mode: normal;
    opacity: 0.9;
    word-break: break-all;
  }
}
