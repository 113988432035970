// Цвета кнопок
$default-red-color: #e23235;
$button-color: #e3282a; // Обычный цвет кнопки
$button-color-hover: #f21417; // Цвет кнопки при наведении
$button-color-active: #e23235; // Цвет кнопки при нажатии
$button-color-disabled: #cc0a0a; // Цвет кнопки при отключении

// Цвета текста
$text-color: rgba(0, 0, 0, 0.8); // Обычный цвет текста
$text-color-input: #495057; // Цвет текста в инпутах

// Цвета иконок
$icon-color: #6c757d; // Цвет обычных иконок
$icon-color-hover: #e23235; // Цвет иконок при наведении
