.area {
  display: flex;
}

.sidebar-my {
  width: 25%;
  min-height: 100vh;
  box-sizing: border-box;
}

.content {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  padding-bottom: 100px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e3282a;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 5px gray;
    margin-bottom: 11px;
    margin-top: 11px;
  }
}
