.diagnostics {
  display: grid;
  grid-template-columns: 2fr 2.5fr;
  grid-template-rows: 30px 650px;
  grid-template-areas:
    'back back'
    'info chat';
  gap: 20px 30px;
  &__back {
    grid-area: back;
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    color: #b4b4b4;
    &::before {
      display: inline-block;
      content: '';
      border-width: 0px 0px 2px 2px;
      margin-right: 5px;
      border-color: #b4b4b4;
      border-style: solid;
      min-width: 8px;
      min-height: 8px;
      transform: rotateZ(45deg);
    }
  }
  &__title {
    color: #242426;
    font-weight: 700;
    font-size: 16px;
    width: 270px;
    position: relative;
  }
  &__info {
    padding: 27px 31px;
    grid-area: info;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-color: #fff;
    overflow-y: auto;
  }
  &__items {
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  &__chat {
    display: flex;
    flex-direction: column;
    padding: 27px 0;
    grid-area: chat;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-color: #fff;
    .diagnostics__title {
      position: relative;
      width: 100%;
      padding-left: 10px;
      &::before {
        // that is a blur filter
        content: '';
        display: inline-block;
        position: absolute;
        top: 19px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0px;
        border-top: 20px solid white;
        filter: blur(1.5px);
        z-index: 10;
      }
    }
  }
  &__send {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-inline: 30px;
    position: relative;
    &::before {
      // that is a blur filter
      content: '';
      display: inline-block;
      position: absolute;
      top: -8px;
      left: 0;
      right: 0;
      bottom: 0;
      margin-top: -6.5px;
      border-top: 20px solid white;
      filter: blur(3px);
    }
    .file-name {
      // classname for a uploading file display
      display: flex;
      gap: 20px;
      height: 20px;
    }
    .inputs-block {
      display: flex;
      gap: 1.5%;
      align-items: center;
      justify-content: space-between;
      flex: 1 1 auto;
      img {
        width: 35px;
        cursor: pointer;
      }
    }
  }
  &__input {
    padding: 15px 10px;
    border: 1.5px solid #dcdee0;
  }
  &__file {
    input[type='file'] {
      display: none;
    }
    cursor: pointer;
    margin: 0;
  }
}
.item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  &__title {
    color: #bcbcbc;
    font-weight: 600;
    font-size: 14px;
  }
  &__list-item {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      display: inline;
      width: 40px;
    }
    &--expired {
      color: red !important;
    }
  }
}
.plug {
  display: flex;
  flex: 1 1 auto;
}
.window {
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
  scrollbar-color: #e3282a #fff;
  scrollbar-width: thin;

  &__date {
    color: #bcbcbc;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
  }
}

.comment {
  padding: 12px 32px 20px 19px;
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__header {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    color: #e3282a;
    font-weight: 600;
  }
  &__time {
    font-weight: 500;
  }
  &__body {
    color: #242426;
    font-size: 14px;
    font-weight: 500;
    a {
      color: blue;
      transition: 0.2s ease;
      &:hover {
        color: red;
        transition: 0.2s ease;
      }
    }
  }
  &__attachment {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 0;
    .img-wrapper {
      display: flex;
      width: 40px;
      justify-content: center;
      align-items: center;
      grid-row-start: span 2;
    }
  }
  &__filename {
    font-size: 15px;
    color: #e3282a;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__filesize {
    font-size: 14px;
    color: #9f9391;
  }
  &--received {
    position: relative;
    background-color: #efefef;
    align-self: start;
    border-radius: 15px 15px 15px 0;
    &::before {
      position: absolute;
      bottom: 0;
      left: -10px;
      display: block;
      border-top-left-radius: 10px;
      content: '';
      width: 10px;
      height: 10px;
      background-color: #efefef;
    }
    .comment__time {
      color: #bcbcbc;
    }
  }
  &--sent {
    position: relative;
    background-color: rgba(227, 40, 42, 0.12);
    align-self: end;
    border-radius: 15px 15px 0 15px;
    &::before {
      position: absolute;
      bottom: 0;
      right: -10px;
      display: block;
      border-top-right-radius: 10px;
      content: '';
      width: 10px;
      height: 10px;
      background-color: rgba(227, 40, 42, 0.12);
    }
  }
}
